<template>

    <div id="cp-tag"></div>

</template>

<script>

import api from '@api/Api';
import FormMixin from '@mixins/FormMixin';
import LogMixin from '@mixins/LogMixin';

export default {

    mixins: [
        FormMixin,
        LogMixin,
    ],

    mounted() {
        this.log('CrowdPower mounted.');
        window.addEventListener('load', () => {
            // Handle queue.
            if (window.cp.q) {
                window.cp.q.forEach((args) => {
                    this.handleCommand(args);
                });
            }
            // Replace queue.
            let self = this;
            window.cp = function() {
                self.handleCommand(arguments);
            }
            // Track page.
            window.cp('page');
        });
    },

    computed: {

        settings() {
            return this.$store.getters.settings;
        },

    },

    methods: {

        handleCommand(args) {
            let command = args[0];
            if (command === 'init') {
                this.log('CP: Init');
                this.handleInit(args[1]);
            } else if (command === 'identify') {
                this.log('CP: Identify');
                this.handleIdentify(args[1]);
            } else if (command === 'event' && typeof args[1] === 'object') {
                this.log('CP: Event');
                this.handleEventV2(args[1]);
            } else if (command === 'event') {
                this.log('CP: Event');
                this.handleEventV1(args[1], args[2]);
            } else if (command === 'page') {
                this.log('CP: Page');
                this.handlePage(args[1]);
            } else if (command === 'charge') {
                this.log('CP: Charge');
                this.handleCharge(args[1]);
            } else if (command === 'tag') {
                this.log('CP: Tag');
                this.handleTag(args[1]);
            } else if (command === 'track') {
                this.log('CP: Track');
                this.handleTrack(args[1]);
            } else if (command === 'push') {
                this.log('CP: Push');
                this.handlePush(args[1]);
            } else if (command === 'form') {
                this.log('CP: Form');
                this.handleForm(args[1]);
            }
        },

        handleInit(projectKey) {
            let settings = this.settings;
            settings.project_key = projectKey;
            this.$store.commit('setSettings', settings);
        },

        handleIdentify(params = {}) {
            // Set settings.
            let settings = this.settings;
            settings.user_id = params.user_id;
            settings.user_token = params.user_token;
            this.$store.commit('setSettings', settings);
            // Ping.
            if (params.user_id || params.email) {
                api().post('customers', params).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        handleEventV1(action, properties = {}) {
            let params = {};
            params.action = action;
            params.properties = properties;
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('events', params).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        handleEventV2(params = {}) {
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('events', params).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        handlePage(params = {}) {
            params.title = document.title;
            params.url = window.location.href;
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('pages', params).then(() => {}, (error) => {
                    this.warn(error.response.data.messag, error);
                });
            }
        },

        handleCharge(params = {}) {
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('charges', params).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        handleTag(params = {}) {
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('tags', params).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        handleTrack(params = {}) {
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Ping.
            if (params.user_id || params.email) {
                api().post('track', { customers: [params] }).then(() => {}, (error) => {
                    this.warn(error.response.data.message, error);
                });
            }
        },

        async handlePush(params = {}) {
            // Append user ID.
            if (!params.user_id) {
                params.user_id = this.settings.user_id;
                params.user_token = this.settings.user_token;
            }
            // Register for push notifications.
            if (params.user_id || params.email) {
                if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
                    return this.warn('Push notifications not supported.');
                }
                const perm = await window.Notification.requestPermission();
                if (perm !== 'granted') {
                    return this.warn('Push notifications not granted.');
                }
                params.project_key = this.settings.project_key;
                const query = new URLSearchParams(params).toString();
                const url = `${window.location.origin}/service-worker.js?${query}`;
                navigator.serviceWorker.register(url, { scope: '/' })
                    .then((reg) => {
                        reg.update();
                    })
                    .catch((error) => {
                        this.warn(error);
                    });
            }
        },

    },

}

</script>