<script>

export default {

    methods: {

        log(payload) {
            // eslint-disable-next-line no-console
            console.log(payload);
        },

        warn(payload) {
            // eslint-disable-next-line no-console
            console.warn(payload);
        },

    }

}

</script>