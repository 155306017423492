import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import VueGtag from 'vue-gtag';
import App from '@js/App';

import { store } from '@/store';

// Google Analytics
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TRACKING_ID }
});

// Config
Vue.use(VueCustomElement);
Vue.config.devtools = false;
Vue.config.productionTip = false;

// Widget embed
App.store = store;
Vue.customElement('cp-root', App);

// Init embed code
// eslint-disable-next-line
window.cp=window.cp||function(){(cp.q=cp.q||[]).push(arguments)};