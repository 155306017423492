<script>

import api from '@api/Api';
import getFormData from 'get-form-data';

export default {

    methods: {

        // Embed form on page.
        handleForm(params = {}) {
            api().get(`forms/${params.id}`)
                .then((response) => {
                    if (document.readyState === 'complete') {
                        this.setFormHtml(params, response.data.data);
                    } else {
                        window.addEventListener('DOMContentLoaded', function() {
                            this.setFormHtml(params, response.data.data);
                        });
                    }
                }, (error) => {
                    this.warn(error.response.data.message, error);
                });
        },

        // Set form HTML.
        setFormHtml(params, form) {
            const target = document.getElementById(params.target);
            if (!target) return;
            // Set content.
            const content = document.createElement('div');
            content.innerHTML = form.embed.html;
            const style = document.createElement('style');
            style.innerHTML = form.embed.css;
            // Set iframe content.
            const iframe = document.createElement('iframe');
            target.appendChild(iframe);
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(style.outerHTML);
            iframe.contentWindow.document.write(content.innerHTML);
            iframe.contentWindow.document.close();
            iframe.style.border = 0;
            iframe.style.height = iframe.contentDocument.body.scrollHeight + 'px';
            iframe.style.width = params.width || '100%';
            // Attach submit function.
            iframe.contentWindow.document.getElementsByTagName('form')[0].onsubmit = (e) => {
                e.preventDefault();
                const form = e.target;
                form.classList.add('cp-loading');
                form.querySelector('button').disabled = true;
                api().post(`forms/${form.attributes['id'].value}`, getFormData(form))
                    .then((response) => {
                        form.classList.remove('cp-loading');
                        form.querySelector('button').disabled = false;
                        // Error
                        const errors = form.getElementsByClassName('cp-error')[0];
                        errors.innerHTML = '';
                        errors.style.opacity = 0;
                        // Success
                        const success = form.getElementsByClassName('cp-success')[0];
                        success.innerHTML = `<span>${response.data.data.success_message}</span>`;
                        success.style.opacity = 1;
                        iframe.style.height = iframe.contentDocument.body.scrollHeight + 'px';
                        // Reset
                        form.reset();
                    }, (error) => {
                        form.classList.remove('cp-loading');
                        form.querySelector('button').disabled = false;
                        // Error
                        const errors = form.getElementsByClassName('cp-error')[0];
                        errors.innerHTML = `<span>${error.response.data.message}</span>`;
                        errors.style.opacity = 1;
                        iframe.style.height = iframe.contentDocument.body.scrollHeight + 'px';
                        // Success
                        const success = form.getElementsByClassName('cp-success')[0];
                        success.innerHTML = '';
                        success.style.opacity = 0;
                    });
            };
        },

    }

}

</script>